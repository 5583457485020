import React from 'react';
import Layout from '../components/App/Layout';
import SEO from '../components/App/SEO';
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import { graphql } from "gatsby";

const pressKitPage = ({ data }) => (

     
        <Layout env={data.site.siteMetadata.env}>
            <Navbar />
            <SEO
                postTitle="Press Kit | BlueMail App"
                postDescription="Press Kit Blue Mail"
                postImage=""
                postURL="press-kit"
                postSEO
            />
        <div className="presskit-area">
            <div className="container">
                
                <div className="main-banner-content center-text pb-70">
                    <h1>Screenshots</h1>
                  
                </div>
                <div className='row'>
                    <div className='col-lg-4 col-sm-12 images-grid'><img style={{maxWidth: '300px', boxShadow: '0 20px 30px rgba(0, 0, 0, 0.2)'}} src={`/img/press-kit/Mobile-1.png`} alt="press-kit" /></div>
                    <div className='col-lg-4 col-sm-12 images-grid'><img style={{maxWidth: '300px', boxShadow: '0 20px 30px rgba(0, 0, 0, 0.2)'}} src={`/img/press-kit/Mobile-2.png`} alt="press-kit" /></div>
                    <div className='col-lg-4 col-sm-12 images-grid'><img style={{maxWidth: '300px', boxShadow: '0 20px 30px rgba(0, 0, 0, 0.2)'}} src={`/img/press-kit/Mobile-3.png`} alt="press-kit" /></div>
                </div>
                <div className='row'>
                    <div className='col-lg-4 col-sm-12 images-grid'><img style={{maxWidth: '300px', boxShadow: '0 20px 30px rgba(0, 0, 0, 0.2)'}} src={`/img/press-kit/Mobile-4.png`} alt="press-kit" /></div>
                    <div className='col-lg-4 col-sm-12 images-grid'><img style={{maxWidth: '300px', boxShadow: '0 20px 30px rgba(0, 0, 0, 0.2)'}} src={`/img/press-kit/Mobile-5.png`} alt="press-kit" /></div>
                    <div className='col-lg-4 col-sm-12 images-grid'><img style={{maxWidth: '300px', boxShadow: '0 20px 30px rgba(0, 0, 0, 0.2)'}} src={`/img/press-kit/Mobile-6.png`} alt="press-kit" /></div>
                </div>
                <div className='row'>
                    <div className='col-lg-4 col-sm-12 images-grid'><img style={{maxWidth: '300px', boxShadow: '0 20px 30px rgba(0, 0, 0, 0.2)'}} src={`/img/press-kit/Mobile-7.png`} alt="press-kit" /></div>
                    <div className='col-lg-4 col-sm-12 images-grid'><img style={{maxWidth: '300px', boxShadow: '0 20px 30px rgba(0, 0, 0, 0.2)'}} src={`/img/press-kit/Mobile-8.png`} alt="press-kit" /></div>
                    <div className='col-lg-4 col-sm-12 images-grid'><img style={{maxWidth: '300px', boxShadow: '0 20px 30px rgba(0, 0, 0, 0.2)'}} src={`/img/press-kit/Mobile-9.png`} alt="press-kit" /></div>
                </div>
                <div className='row'>
                    <div className='col-lg-4 col-sm-12 images-grid'><img style={{maxWidth: '300px', boxShadow: '0 20px 30px rgba(0, 0, 0, 0.2)'}} src={`/img/press-kit/Mobile-10.png`} alt="press-kit" /></div>
                    <div className='col-lg-4 col-sm-12 images-grid'><img style={{maxWidth: '300px', boxShadow: '0 20px 30px rgba(0, 0, 0, 0.2)'}} src={`/img/press-kit/Mobile-11.png`} alt="press-kit" /></div>
                    <div className='col-lg-4 col-sm-12 images-grid'><img style={{maxWidth: '300px', boxShadow: '0 20px 30px rgba(0, 0, 0, 0.2)'}} src={`/img/press-kit/Mobile-12.png`} alt="press-kit" /></div>
        
                </div>

                <div className='row mt-50'>
                    <div className='col-12 images-grid'><img style={{ width: '100%', boxShadow: '0 20px 30px rgba(0, 0, 0, 0.2)'}} src={`/img/press-kit/Artboard.webp`} alt="press-kit" /></div>
                    <div className='col-12 images-grid'><img style={{ width: '100%', boxShadow: '0 20px 30px rgba(0, 0, 0, 0.2)'}} src={`/img/press-kit/Artboard-1.webp`} alt="press-kit" /></div>
                    <div className='col-12 images-grid'><img style={{ width: '100%', boxShadow: '0 20px 30px rgba(0, 0, 0, 0.2)'}} src={`/img/press-kit/Artboard-2.webp`} alt="press-kit" /></div>
                    <div className='col-12 images-grid'><img style={{ width: '100%', boxShadow: '0 20px 30px rgba(0, 0, 0, 0.2)'}} src={`/img/press-kit/Artboard-3.webp`} alt="press-kit" /></div>
                    <div className='col-12 images-grid'><img style={{ width: '100%', boxShadow: '0 20px 30px rgba(0, 0, 0, 0.2)'}} src={`/img/press-kit/Artboard-4.webp`} alt="press-kit" /></div>
                    <div className='col-12 images-grid'><img style={{ width: '100%', boxShadow: '0 20px 30px rgba(0, 0, 0, 0.2)'}} src={`/img/press-kit/Artboard-5.webp`} alt="press-kit" /></div>
                    <div className='col-12 images-grid'><img style={{ width: '100%', boxShadow: '0 20px 30px rgba(0, 0, 0, 0.2)'}} src={`/img/press-kit/Artboard-6.webp`} alt="press-kit" /></div>
                    <div className='col-12 images-grid'><img style={{ width: '100%', boxShadow: '0 20px 30px rgba(0, 0, 0, 0.2)'}} src={`/img/press-kit/Artboard-7.webp`} alt="press-kit" /></div>
                    <div className='col-12 images-grid'><img style={{ width: '100%', boxShadow: '0 20px 30px rgba(0, 0, 0, 0.2)'}} src={`/img/press-kit/Artboard-8.webp`} alt="press-kit" /></div>
                </div>
            </div>
            
            
        </div>
        <Footer />
        </Layout>
);

export default pressKitPage;

export const pageQuery = graphql`
  query PressKitPageIndexQuery {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "privacy" } } }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            templateKey
          }
        }
      }
    }
    site {
      siteMetadata {
        env
      }
    }
  }
`